/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

import "./layout.css"
import 'aos/dist/aos.css'

class Layout extends React.Component {
  componentDidMount() {
      const AOS = require('aos');
      this.aos = AOS
      this.aos.init()
  }

  componentDidUpdate() {
      this.aos.refresh()
  }
  render() {
    const {location, children} = this.props
    return (
      <div>
          {children}
      </div>
    );
  }
  }

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

